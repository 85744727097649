div.form-wrapper{
    height: 1000px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #006199;
    overflow-x: hidden;
    
}

.form-wrapper h2{
    font-family: "Playfair Display", Georgia, serif;
}

div.form-wrapper form{
    margin-right: 50px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 hsl(0, 0%, 74%);
    padding: 20px;
    height: 800px;
    background-color: #ffffff;
}

.main-career-form{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.car{
    margin-top: 70px;
    font-family: "Playfair Display", Georgia, serif;
}

.car input{
    width: 600px; /* width of the input fields */
    /* other styles for input fields */
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-family: "Playfair Display", serif;
    font-size: 18px;
}

input[type=file]{
    height: 45px;
    width: 340px;
    max-width: 300px;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
    margin-left: 10px;
}

input[type=file]::file-selector-button{
    border: none;
    background: #006199;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    font-family: "Playfair Display", serif;
    

}

#row-file{
    position: relative;
}

input[type=file]::file-selector-button:hover{
    background: #0d45a5;
}

#submit-form-info{
    font-size: 1em;
    font-family: "Playfair Display", serif;
    padding: 0.75rem 1.5rem; /* Replaces width and height */
    color: #ffffff; /* Same color as the section background */
    background-color: #006199; /* Section background color */
    font-weight: 560; /* Slightly bold font */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    cursor: pointer; /* Changes the cursor on hover */
    text-decoration: none; /* Removes underline if the button is a link */
}

#submit-form-info:hover{
    background-color: #ffffff; /* Section background color */
    color: #000000; /* White text on hover */
}

@media screen and (max-width: 1920px) and (max-height: 634px){
    div.form-wrapper{
        height: 200vh;
    }
}

@media screen and (max-width: 1619px){
    div.form-wrapper form{
        margin-left: 40px;
    }
}

@media screen and (max-width: 1619px){
    .car{
        margin-top: 40px;
    }

    .car input{
        width: 460px;
    }

}

@media screen and (max-width: 1280px) and (max-height: 800px){
    div.form-wrapper{
        height: 140vh;
    }
}

@media screen and (max-width: 1024px) and (max-height: 1366px){

    div.form-wrapper{
        height: 100vh;
    }

    div.form-wrapper form{
        height: 600px;
        margin-left: 50px;
    }

    .car input{
        margin-top: 4px;
    }

    .form-wrapper h2{
        font-size: 1.4em;
    }
}

@media screen and (max-width: 912px) and (max-height: 1368px){
  
    div.form-wrapper form{
        margin-left: 40px;
    }
}

@media screen and (max-width: 870px){
    .car{
        margin-top: 30px;
    }
}

@media screen and (max-width: 752px){
    .car{
        margin-top: 30px;
    }

    .car input{
        width: 500px;
    }
}

@media screen and (max-width: 705px){

    .car input{
        width: 400px;
    }
}

@media screen and (max-width: 654px){
    .car{
        margin-top: 30px;
    }
}

@media screen and (max-width: 618px){
    .car{
        margin-top: 20px;
    }

    .car input{
        width: 370px;
    }
}

@media screen and (max-width: 612px){
    .car{
        margin-top: 30px;
    }

   
}

@media screen and (max-width: 602px){
    .car{
        margin-top: 25px;
    }

    .car input{
        width: 370px;
    }
}

@media screen and (max-width: 582px){
    div.form-wrapper form{
        width: 400px;
        height: 900px;
    }

    .car input{
        width: 378px;
    }

    .car{
        margin-top: 50px;
    }


}

@media screen and (max-width: 540px) and (max-height: 720px){

    div.form-wrapper{
        height: 180vh;
    }

    div.form-wrapper form{
        width: 900px;
        height: 700px;
    }

    .form-wrapper h2{
        font-size: 1em;
    }

    .car{
        margin-top: 26px;
    }
}

@media screen and (max-width: 530px){
    div.form-wrapper{
        width: 120vw;
    }
}

@media screen and (max-width: 491px){
    div.form-wrapper{
        width: 153vw;
    }
}

@media screen and (max-width: 464px){
    div.form-wrapper form{
        width: 320px;
        height: 1000px;
    }

    .car input{
        width: 300px;
    }

    .car{
        margin-top: 70px;
    }
    
}

@media screen and (max-width: 430px) and (max-height: 932px){

    div.form-wrapper{
        height: 150vh;
        width: 154vw;
    }

    div.form-wrapper form{
        height: 900px;
        margin-top: 100px;
        margin-bottom: 50px;
    }

    .car{
        margin-top: 50px;
    }
    

}

@media screen and (max-width: 422px){
    div.form-wrapper{
        width: 170vw;
    }
  }

@media screen and (max-width: 414px) and (max-height: 896px){
    div.form-wrapper{
        height: 1000px;
    }

    div.form-wrapper form{
        height: 800px;
    }

    .form-wrapper h2{
        font-size: 1.2em;
    }

    .car{
        margin-top: 44px;
    }

}

@media screen and (max-width: 390px) and (max-height: 844px){

    div.form-wrapper form{
        height: 800px;
        width: 200vw;
    }

    .car{
        margin-top: 70px;
    }
}
@media screen and (max-width: 375px){
    div.form-wrapper{
        width: 172vw;
    }
}
@media screen and (max-width: 375px) and (max-height: 667px){

    div.form-wrapper{
        height: 150vh;
        width: 182vw;
    }

    div.form-wrapper form{
        width: 500px;
        height: 600px;
    }

    .car input{
        width: 240px;
    }


    .car{
        margin-top: 30px;
    }
}

@media screen and (max-width: 362px){
    div.form-wrapper{
        width: 202vw;
    }
  }


@media screen and (max-width: 360px) and (max-height: 740px){

    div.form-wrapper form{
        width: 5--px;
    }

    .car input{
        width: 260px;
    }

}

@media screen and (max-width: 360px) and (max-height: 640px){
    div.form-wrapper form{
        width: 500px;
        height: 600px;
    }

    .car input{
        width: 400px;
    }

    .car{
        margin-top: 30px;
    }
    
}

@media screen and (max-width: 314px){
    div.form-wrapper{
        width: 283vw;
        height: 220vh;
    }

    .form-wrapper h2{
        font-size: 2em;
    }

    div.form-wrapper form{
        width: 600px;
        height: 1200px;
    }

    .car{
        margin-top: 90px;
    }

    .car label{
        font-size: 2em;
    }

    #submit-form-info{
        height: 100px;
        width: 160px;
        font-size: 2em;
    }

    .car input{
        height: 50px;
    }

}

@media screen and (max-width: 300px){
    div.form-wrapper form{
        width: 800px;
        height: 1800px;
    }

    .car input{
        width: 400px;
    }

    .car{
        margin-top: 170px;
    }
    
}

@media screen and (max-width: 280px) and (max-height: 653px){

    .form-wrapper h2{
        font-size: 2em;
    }


    div.form-wrapper form{
        height: 800px;
        margin-top: 20px;
    }

    .car{
        margin-top: 10px;
    }
}

@media screen and (max-height: 558px){
    div.form-wrapper{
        height: 220vh;
    }
}

@media screen and (max-height: 440px){
    div.form-wrapper{
        height: 250vh;
    }
}

@media screen and (max-height: 346px){
    div.form-wrapper{
        height: 300vh;
    }
}

@media screen and (max-height: 289px){
    div.form-wrapper{
        height: 500vh;
    }
}

@media screen and (max-width: 277px){
    div.form-wrapper{
        width: 1110px;

    }
}

@media screen and (max-width: 275px){
    div.form-wrapper{
        width: 1100px;

    }
}

@media screen and (max-width: 174px){
    div.form-wrapper{
        width: 700px;

    }

}




