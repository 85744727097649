.contact-section{
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 900px;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 1024px) and (max-height: 1366px){

}

@media screen and (max-width: 912px) and (max-height: 1368px){

}

@media screen and (max-width: 530px){
  .contact-section{
    width: 120vw;
  }
}

@media screen and (max-width: 491px){
  .contact-section{
    width: 152vw;
    height: 100vh;
  }
}

@media screen and (max-width: 430px) and (max-height: 932px){
  .contact-section{
    width: 154vw;
  }
}

@media screen and (max-width: 422px){
  .contact-section{
    width: 170vw;
    height: 100vh;
  }
}


@media screen and (max-width: 414px) and (max-height: 896px){
  .contact-section{
    width: 170vw;
  }
}

@media screen and (max-width: 412px) and (max-height: 915px){
  .contact-section{
    width: 170vw;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px){
  .contact-section{
    width: 182vw;
    height: 170vh;
  }
}

@media screen and (max-width: 362px){
  .contact-section{
    width: 200vw;
    height: 120vh;
  }
}

@media screen and (max-width: 360px) and (max-height: 740px){
  .contact-section{
    width: 200vw;
    height: 180vh;
  }

}

@media screen and (max-width: 314px){
  .contact-section{
    width: 280vw;
    height: 180vh;
  }
}

@media screen and (max-width: 280px) and (max-height: 653px){
  .contact-section{
    width: 282vw;
    height: 230vh;
  }

}

@media screen and (max-width: 276px){
  .contact-section{
    width: 400vw;
    height: 300vh;
  }


}