
.about-con{
    margin: 0px;
    padding: 0px;
    background-color: #ffff;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.about-1{
    display: flex;
}

.about-2{
    display: flex;
    margin-top: 110px;
}

.about-2 .hero-content2{
    position: relative;
    top: 90px;
}

.major-content{
    position: relative;
    padding: 5rem 4rem;
    font-family: "Playfair Display", Georgia, serif;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hero-content{
    flex: 1;
    width: 600px;
    margin: 0px 25px;
}

.hero-content h2{
    font-size: 1em;
    margin-bottom: 20px; 
    text-align: center;
}

.hero-content p{
    text-align: center;
    font-size: 1.4em;
    line-height: 1.5;
    margin-bottom: 40px; 
}

.hero-content2{
    flex: 1;
    width: 600px;
    margin: 0px 25px;
}

.hero-content2 p{
    text-align: center;
    font-size: 1.4em;
}

.about-info-1-image{
    flex: 1;
    width: 70%;
   
    
}

#first_img{
    position: relative;
    left: 400px;
}

.about-info-2-image{
    flex: 1;
    width: 600px;
    margin: auto;
}

#first_img, #second_img{
    width: 60%;
    height: auto;
    border-radius: 10px;
    transition: transform .5s;
}

#first_img:hover, #second_img:hover{
    transform: scale(1.1);
 }

@media screen and (max-width: 1935px){
    #first_img{
        position: relative;
        left: 300px;
    }
 } 

 @media screen and (max-width: 1509px){
    .major-content{
        height: 70vh;
    }
 }

 @media screen and (max-width: 1489px){
    #first_img{
        position: relative;
        left: 270px;
    }
 }
 
@media screen and (max-width: 1251px){
    .major-content{
        height: 90vh;
    }

    #first_img{
        position: relative;
        left: 150px;
    }

    .about-2{
        margin-top: 300px;
    }

}

@media screen and (max-width: 1024px) and (max-height: 1366px){
    .about-2{
        margin-top: 400px;
    }

    #second_img{
        position: relative;
        top: 90px;
    }
}

@media screen and (max-width: 912px) and (max-height: 1368px){
    .about-2{
        margin-top: 400px;
    }

    #second_img{
        position: relative;
        top: 90px;
    }
}

@media screen and (max-width: 875px){
    .about-2 p{
        position: relative;
        bottom: 70px;
    }
}

@media screen and (max-width: 853px) and (max-height: 1280px){
    .about-2{
        margin-top: 400px;
    }

    #second_img{
        position: relative;
        top: 10px;
    }
}

@media screen and (max-width: 851px){
    #first_img{
        width: 300px;
        left: 50px;
    }

    #second_img{
        width: 300px;
    }
}

@media screen and (max-width: 851px){
    .about-con{
        height: 150vh;
    }

    .about-2{
        margin-top: 370px;
    }
}

@media screen and (max-width: 820px) and (max-height: 1180px){
    .about-con{
        height: 120vh;
    }

}

@media screen and (max-height: 1085px){
    .about-con{
        height: 120vh;
    }
}

@media screen and (max-width: 616px){
    .about-2{
        margin-top: 220px;
    }

    .hero-content p{
        font-size: 1.2em;
    }

    .hero-content2 p{
        
        font-size: 1.2em;
    }
}

@media screen and (max-height: 909px){
    .about-con{
        height: 140vh;
    }
}

@media screen and (max-height: 779px){
    .about-con{
        height: 160vh;
    }
}

@media screen and (max-width: 540px) and (max-height: 720px){
    .about-1 .about-info-1-image{
        position: relative;
        right: 40px;
     }

     .about-con{
        height: 250vh;
    }
}

@media screen and (max-width: 530px){
    .about-con{
        width: 119.8vw;
        height: 160vh;
    }

    .about-2{
        margin-top: 390px;
    }
}

@media screen and (max-width: 491px){
    .about-con{
        width: 153vw;
        height: 170vh;
    }

    .hero-content p{
        font-size: 1.7em;
    }

    .hero-content2 p{
        
        font-size: 1.7em;
    }

    .about-2{
        margin-top: 450px;
    }
}

@media screen and (max-width: 439px){

    .about-2{
        margin-top: 60px;
    }
}

@media screen and (max-width: 430px) and (max-height: 932px){
    .about-con{
        width: 154vw;
        height: 120vh;
    }

    .about-1{
        justify-content: center;  
        flex-direction: column;
        align-items: center;
    }

    .about-1 .about-info-1-image{
        position: relative;
        left: 78px;
        width: 100%;
     }

     .hero-content h2{
        text-align: center; 
        font-size: 40px;
    }
    
    .hero-content p{
        text-align: center;
        font-size: 22px;
    }

    .about-2{
        margin-top: 100px;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
    }

    .hero-content2 p{
        position: relative;
        text-align: center;
        bottom: 110px;
        font-size: 22px;
    }

    #second_img{
        position: relative;
        top: 10px;
        left: 130px;
        margin: 0;
        padding: 0;
    }

}

@media screen and (max-width: 422px){
    .about-con{
        width: 170vw;
        height: 140vh;
    }

    .about-1{
        justify-content: center;  
        flex-direction: column;
        align-items: center;
    }

    .about-2{
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 300px;
    }

    .about-1 .about-info-1-image{
        position: relative;
        right: 50px;
        width: 300px;
     }
    
    .hero-content p{
        text-align: center;
        font-size: 1.8em;
    }

    .hero-content2 p{
        position: relative;
        text-align: center;
        bottom: 110px;
        font-size: 1.8em;
    }

    .about-2 .about-info-2-image{
        position: relative;
        width: 300px;
        top: 10px;
        left: 10px;
        
    }
    
}

@media screen and (max-width: 414px) and (max-height: 896px){
    .about-con{
        width: 168vw;
        height: 180vh;
    }

    .about-1 .about-info-1-image{
        position: relative;
        margin-right: 250px;
        width: 300px;
     }

    .about-2 .about-info-2-image{
        position: relative;
        width: 300px;
        top: 10px;
        margin-right: 500px;
        
    }
}

@media screen and (max-width: 412px) and (max-height: 915px){
    .about-con{
        width: 170vw;
        height: 170vh;
        
    }

    .about-1 .about-info-1-image{
        position: relative;
        margin-right: 250px;
        width: 300px;
     }

    .about-2 .about-info-2-image{
        position: relative;
        width: 300px;
        top: 10px;
        margin-right: 500px;
        
    }
}

@media screen and (max-width: 390px) and (max-height: 844px){
    .about-con{
        width: 170vw;
        
    }
}

@media screen and (max-height: 675px){
    .about-con{
        height: 190vh;
    }
}

@media screen and (max-width: 375px) and (max-height: 667px){
    .about-con{
        width: 181vw;
        height: 250vh;
    }

    .footer{
        height: 200vh;
        width: 102vh;
    }
}

@media screen and (max-width: 362px){
    .about-con{
        width: 194.5vw;
        height: 170vh;
    }

    .about-2{
        margin-top: 500px;
    }

}

@media screen and (max-width: 360px) and (max-height: 740px){
    .about-con{
        width: 193vw;
        height: 190vh;
    }

    .about-2{
        margin-top: 100px;
    }

    .footer{
        height: 170vh;
    }
}

@media screen and (max-width: 360px) and (max-height: 640px){
    .footer{
        width: 200vw;
        height: 190vh;
    }
}

@media screen and (max-width: 362px){
    .about-con{
        width: 202vw;
        
    }
}

@media screen and (max-height: 579px){
    .about-con{
        height: 210vh;
    }
}

@media screen and (max-width: 344px) and (max-height: 882px){
    .about-2{
        margin-top: 200px;
    }

    .about-con{
        height: 170vh;
    }
}

@media screen and (max-width: 314px){
    .about-con{
        width: 283vw;
        height: 200vh;
    }

    .about-2{
        margin-top: 500px;
    }

    .about-1 .about-info-1-image{
        position: relative;
        right: 50px;
        width: 300px;
     }
    
    .hero-content p{
        font-size: 2.4em;
    }

    .hero-content2 p{
        font-size: 2.4em;
    }

    .about-2 .about-info-2-image{
        position: relative;
        width: 300px;
        top: 10px;
        left: 10px; 
    }
}

@media screen and (max-width: 280px) and (max-height: 653px){
    .about-con{
        width: 270vw;
        height: 200vh;
    }

    .footer{
        height: 185vh;
        width: 280vw;
    }

 
}

@media screen and (max-width: 277px){
    .about-con{
        width: 400vw;
        height: 530vh;
    }

    .about-1{
        margin-top: 50%;
    }

    .about-1 .about-info-1-image{
        right: 130px;
       
     }
     #first_img{
        height: 400px;
        width: 500px;
     }

     #second_img{
        height: 400px;
        width: 500px;
         
     }

    .hero-content h2{
        text-align: center; 
        font-size: 90px;
    }
    
    .hero-content p{
        font-size: 80px;
    }

    .hero-content2 p{
        font-size: 80px;
    }

    .about-2 .about-info-2-image{
        margin-right: 440px;
     }
}

@media screen and (max-height: 510px){
    .about-con{
        height: 230vh;
    }
}

@media screen and (max-height: 473px){
    .about-con{
        height: 260vh;
    }
}





 

