.posting-container{
    position: relative;
    bottom: 500px;
    
}

.postings-box{
    display: inline-block;
    width: 1000px;
    height: 60px;
    background: #ffff;
    display: flex;
    top: 160px;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    position: relative;
    left: 10px;
    transition: .5s;
    position: relative;
}

.postings-box:hover{
    transform: scale(1.01);
}

.posting-container h2{
    color: #000000;
    font-family: "Playfair Display", Georgia, serif;
    font-weight: normal;
    position: relative;
    left: 390px;
    top: 120px;
}

.postings-box h3{
    font-weight: normal;
    font-family: "Playfair Display", Georgia, serif;
}

.DSP-BUTTON{
    position: relative;
    left: 470px;
    width: 80px;
    height: 40px;
    cursor: pointer;
    font-family: "Playfair Display", serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    background: #ffff;

}

.DSP-BUTTON:hover{
    background-color: #006199; /* Section background color */
    color: #ffffff; /* White text on hover */
}

@media screen and (max-width: 1024px) and (max-height: 1366px){
    .postings-box{
        width: 800px;
        
    }

    .posting-container h2{
        left: 310px;
        
    }

    .DSP-BUTTON{
        left: 300px;
    }
}

@media screen and (max-width: 912px) and (max-height: 1368px){
    .postings-box{
        width: 700px;
        
    }

    .posting-container h2{
        left: 250px;
        
    }

    .DSP-BUTTON{
        left: 200px;
    }
}

@media screen and (max-width: 713px){
    .postings-box{
        width: 600px;
    }

    .DSP-BUTTON{
        left: 100px;
    }
}

@media screen and (max-width: 637px){
    .postings-box{
        width: 600px;
        
    }

    .DSP-BUTTON{
        left: 100px;
    }
}

@media screen and (max-width: 609px){
    .postings-box{
        width: 500px;
    }

    .DSP-BUTTON{
        left: 20px;
    }

    .posting-container h2{
        left: 160px;
        
    }
}
@media screen and (max-width: 534px){
    .postings-box{
        width: 500px;
        left: 1px;
    }

    .DSP-BUTTON{
        left: 20px;
    }
}

@media screen and (max-width: 508px){
    .postings-box{
        width: 400px;
        left: 1px;
    }

    .DSP-BUTTON{
        left: -10px;
    }

    .posting-container h2{
        left: 110px;
        
    }
}

@media screen and (max-width: 430px) and (max-height: 932px){
    .postings-box{
        width: 400px;
        
    }

    .DSP-BUTTON{
        left: -13px;
    }

    .posting-container h2{
        left: 110px;
        
    }

}

@media screen and (max-width: 414px) and (max-height: 896px){
    .postings-box{
        width: 400px;
        
    }

    .posting-container h2{
        left: 105px;
        
    }

    .DSP-BUTTON{
        left: -13px;
    }
}

@media screen and (max-width: 407px){
    .posting-container h2{
        left: 55px;
        
    }

    .postings-box h3{
        font-size: 1em;
    }


    .postings-box{
        width: 300px;
        
    }
}

@media screen and (max-width: 375px) and (max-height: 667px){
    .DSP-BUTTON{
        left: -13px;
    }
  }

@media screen and (max-width: 360px) and (max-height: 640px){
    .DSP-BUTTON{
        left: -13px;
    }
}

@media screen and (max-width: 314px){
    .postings-box{
        width: 700px;
        height: 200px;
    }

    .postings-box h3{
        font-size: 2em;
    }

    .posting-container h2{
        left: 200px;
        font-size: 2em;
        
    }

    .DSP-BUTTON{
        font-size: 2em;
        top: 20px;
        left: -30px;
        width: 120px;
        height: 70px;
    }

}

@media screen and (max-width: 280px) and (max-height: 653px){
    .postings-box{
        width: 600px;
        
    }

    .posting-container h2{
        left: 170px;
        
    }
}

@media screen and (max-width: 174px){
    .postings-box{
        width: 600px;
        
    }

    .posting-container h2{
        left: 115px;
        
    }
}