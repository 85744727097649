.job-info-parent{
    display: flex;
    justify-content: center; /* Adjusts horizontal alignment */
    align-items: center; /* Adjusts vertical alignment */
    width: auto;
    overflow-x: hidden;
 
}

.job-info{
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 850px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width:100%;
    
}

@media screen and (max-width: 752px){
  .job-info{
    height: 100vh;
  }
}

@media screen and (max-width: 540px) and (max-height: 720px){
  .job-info{
    height: 150vh;
  }
}

@media screen and (max-width: 530px){
  .job-info-parent{
    width: 120vw;
  }
}

@media screen and (max-width: 491px){
  .job-info-parent{
      width: 152vw;
  }
}


@media screen and (max-width: 430px) and (max-height: 932px){
  .job-info-parent{
    width: 154vw;
  }
}

@media screen and (max-height: 828px){
  .job-info{
    height: 150vh;
  }

}

@media screen and (max-width: 422px){
  .job-info-parent{
      width: 170vw;
  }
}

@media screen and (max-width: 407px){
  .job-info{
    height: 150vh;
  }

}

@media screen and (max-width: 375px) and (max-height: 667px){
  .job-info-parent{
    width: 182vw;
  }
}

@media screen and (max-width: 362px){
  .job-info-parent{
      width: 200vw;
  }
}

@media screen and (max-width: 314px){
  .job-info-parent{
      width: 281vw;
  }
}

@media screen and (max-width: 282px){
  .job-info{
    height: 200vh;
  }
}


@media screen and (max-width: 280px) and (max-height: 653px){
  .job-info{
    height: 300vh;
  }
}

@media screen and (max-height: 558px){
  .job-info{
    height: 180vh;
  }

}

@media screen and (max-width: 277px){
  .job-info-parent{
    width: 400vw;
  }
}

@media screen and (max-width: 174px){
  .job-info{
    height: 400vh;
  }

}

