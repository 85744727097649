.programs-section{
    position: relative;
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
}

.box{
    transition: background-color 0.3s ease;
    overflow: hidden;
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-left{
    font-family: "Playfair Display", Georgia, serif;
    font-weight: bold;
    border-radius: 25px;
    background: #6b9fcf;
    padding: 20px;
    width: 160px;
    height: 110px;
    top: 15%;
    left: 26%;
    cursor: pointer; /* Changes the cursor on hover */
}

.top-right{
    font-family: "Playfair Display", Georgia, serif;
    font-weight: bold;
    border-radius: 25px;
    background: #6b9fcf;
    padding: 20px;
    width: 160px;
    height: 110px;
    top: 15%;
    right: 26%;
    cursor: pointer; /* Changes the cursor on hover */
}

.bottom-left{
    font-family: "Playfair Display", Georgia, serif;
    font-weight: bold;
    border-radius: 25px;
    background: #6b9fcf;
    padding: 20px;
    width: 160px;
    height: 110px;
    bottom: 15%;
    left: 26%;
    cursor: pointer; /* Changes the cursor on hover */
}

.bottom-right{
    font-family: "Playfair Display", Georgia, serif;
    font-weight: bold;
    border-radius: 25px;
    background: #6b9fcf;
    padding: 20px;
    width: 160px;
    height: 110px;
    bottom: 15%;
    right: 26%;
    cursor: pointer; /* Changes the cursor on hover */
}

/*.more-info{
    font-family: "Playfair Display", Georgia, serif;
    font-weight: bold;
    position: absolute;
    cursor: pointer;
    text-align: center;
    transform: translateY(100%);
}
*/

.box:hover{
    background-color: white;
    border-color: #6b9fcf;
}


.top-left, .top-right, .bottom-left, .bottom-right{
    transition: transform .5s;
}

.top-left:hover, .top-right:hover, .bottom-left:hover, .bottom-right:hover{
    transform: scale(1.1);
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(255, 255, 255, 0.8);*/
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.textbox{
    position: relative;
    font-family: "Playfair Display", Georgia, serif;
    border-radius: 25px;
    background: #f8f8f8;
    border: 1px solid #dfdfdf;
    color: #717171;
    font-size: 1em;
    height: 300px;
    width: 600px;
    padding: 10px;
    text-align: center;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from{
        opacity: 0;
        transform: scale(0.5);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}

#back-to-aboutUs-button{
    position: relative;
    bottom: -20%;
    left: 0%;
    margin-top: 5%;
    font-family: "Playfair Display", serif;
    padding: 0.75rem 1.5rem; /* Replaces width and height */
    color: #006199; /* Same color as the section background */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    cursor: pointer; /* Changes the cursor on hover */
}


#back-to-aboutUs-button:hover{
    background-color: #006199; /* Section background color */
    color: #ffffff; /* White text on hover */
}

.info-about-service1{
    line-height: 30px;
    position: relative;
    top: 15.8%;
}


