.contact-form Form textarea{
    width: 700px; /* width of the input fields */
    height: 200px;
    /* other styles for input fields */
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-family: "Playfair Display", serif;
    font-size: 18px;
    resize: none;
}

.contact-form Form input{
    width: 300px; /* width of the input fields */
    /* other styles for input fields */
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-family: "Playfair Display", serif;
    font-size: 18px;
    resize: none;
}

@media screen and (max-width: 912px){
    div.form-wrapper .contact-form Form{
        height: 800px;
    
    }
}

@media screen and (max-width: 912px) and (max-height: 1368px){
    div.form-wrapper .contact-form Form .car{
        margin-top: 90px;
    }
}

@media screen and (max-width: 853px) and (max-height: 1280px){
    div.form-wrapper .contact-form Form{
        height: 900px;
    }
}

@media screen and (max-width: 782px){
    div.form-wrapper .contact-form Form{
        height: 800px;
        width: 600px;
    
    }

    .contact-form Form textarea{
        width: 550px;
    }
}

@media screen and (max-width: 768px) and (max-height: 1024px){
    div.form-wrapper .contact-form Form{
        height: 800px;
        width: 600px;
    }

    .contact-form Form textarea{
        width: 560px;
    }
}

@media screen and (max-width: 664px){
    div.form-wrapper .contact-form Form{
        height: 800px;
        width: 500px;
    
    }

    .contact-form Form textarea{
        width: 480px;
    }
}

@media screen and (max-width: 540px) and (max-height: 720px){
    div.form-wrapper .contact-form Form{
        width: 400px;
        height: 1000px;
    }

    .contact-form Form textarea{
        width: 360px;
    }

    div.form-wrapper .contact-form Form .car{
        margin-top: 130px;
    }

}

@media screen and (max-width: 430px) and (max-height: 932px){
    div.form-wrapper .contact-form Form{
        width: 500px;
        height: 1000px;
    }

    .contact-form Form textarea{
        width: 470px;
    }

    div.form-wrapper .contact-form Form .car{
        margin-top: 130px;
    }
}

@media screen and (max-width: 414px) and (max-height: 896px){
    div.form-wrapper .contact-form Form{
        width: 500px;
        height: 700px;
    }

    div.form-wrapper .contact-form Form .car{
        margin-top: 80px;
    }
}

@media screen and (max-width: 360px) and (max-height: 740px){
    div.form-wrapper .contact-form Form{
        width: 600px;
        height: 800px;
    }
}

@media screen and (max-width: 314px){
    div.form-wrapper .contact-form Form{
        width: 800px;
        height: 2000px;
    }

    div.form-wrapper .contact-form Form .car{
        margin-top: 300px;
    }

    .contact-form Form input{
        width: 600px;
    }

    .contact-form Form textarea{
        width: 770px;
        height: 400px;
    }
}