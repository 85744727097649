*{
  margin: 0;
  padding: 0;
}

body{
  overflow-x: hidden;
}

.section1{
  height: 146px;
  background-color: #ffffff;
  z-index: 9999;
}

/* Style for the company logo */
#companyLogo{
  margin-left: 90%;
  height: 150px;
  width: 150px;
}

.company_image a{
  margin: 0;
  padding: 0;
}

/* Header container style */
.header{
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes child elements with space in between */
  padding: 0%;
  height: 100%;
  margin: 0 auto;
}

.sticky{
  position: fixed;
  top: 0;
  width: 100%;
}

/* Navbar styling */
.navbar {
  height: 60px;
  list-style-type: none;  /* remove bullets */
  text-align: right;
  width: 50%;
  margin-right: 25%;
  padding: 0%;  /* some vertical padding */
  background-color: #ffffff;
  flex-wrap: no-wrap; /* Prevents wrapping of navbar items */
  display: flex;
  justify-content: space-around;  
  position: sticky;
  top: 0;
}

.navbar .nav-link {
  color: #000000;
  font-family: "Playfair Display", serif;
  background: linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat;
  text-align: center;
  padding: 7.0%;
  text-decoration: none;
  font-size: 1.05em;  /* slightly larger text for links */
  transition: 1s background-size;
  white-space: nowrap;
}

/* Hover and active state for navbar links */
.navbar .nav-link:hover {
  color: #007BFF; /* Subtle color change on hover */
  background-size: 100% .1em;
}

.navbar .nav-link.left{
  background-position: left bottom;
}

.navbar ul{
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.navbar ul li:not(:last-child){
  margin-right: 60px;
}

.navbar ul .nav-link{
  text-decoration: none;
}

.navbar ul .nav-link.active{
  position: relative;
}


.menu-icon{
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: none;
  margin-right: 20%;
  
}

.menu-icon .menu-icon-close{
  color:#ffffff;
}

@media screen and (max-width: 523px) {
  #companyLogo{
    display: none;
  }

  .menu-icon{
    top: 50%;
    left: 50%;
    position: absolute;
    margin-left: 6%;
  }

}

@media screen and (max-width: 515px) {
  .section1{
    width: 120vw;
  }

  .menu-icon{
    margin-left: 6.7%;
  }

}

@media screen and (max-width: 491px) {
  .section1{
    width: 140vw;
  }

  .bars{
    transform: scale(1.2);
    
  }

  .menu-icon{
    margin-left: 20.7%;
  }

  @media screen and (max-width: 416px) {
    .section1{
      width: 160vw;
    }
  
    .menu-icon{
      margin-left: 28%;
    }

    @media screen and (max-width: 362px) {
      .section1{
        width: 200vw;
      }

      .bars{
        transform: scale(1.5);
      }
    
    
    }
  
  }

}

@media screen and (max-width: 375px) {
  .menu-icon{
    margin-left: 134px;
  }
}

@media screen and (max-width: 360px) and (max-height: 740px){
  .menu-icon{
    margin-left: 153px;
  }
}

@media screen and (max-width: 360px) and (max-height: 640px){
  .menu-icon{
    margin-left: 44%;
  }
}

@media screen and (max-width: 314px) {
  .section1{
    width: 259vw;
  }

  .menu-icon{
    position: relative;
    left: 200px;
    top: 7px;
  }

}

@media screen and (max-width: 1181px) {
  .menu-icon{
    display: block;
    opacity: 1;
   
  }

  #companyLogo{
    margin-left: 60%;
    height: 150px;
    width: 150px;
  }

  @media screen and (max-width: 505px) {
    #companyLogo{
      margin-left: 20%;
      width: 40%;
      height: 40%;
    }
  
  }

  .navbar .nav-link {
    color: #ffffff; /* Subtle color change on hover */
  }


  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #006199;
    transition: 0.5s;
    transform: translateY(-400vh);
  }

  .responsive_nav{
    transform: none;
  }

  .section1{
    position: relative;
    z-index: 9999;
    width: 100%;
  }

  .navbar ul{
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .navbar ul li{
    margin-top: 70px;
  }
 
  .navbar .career{
    margin-right: 58px;
  }

  .close{
    position: absolute;
    top: 2rem;
    right: 2rem;
    margin-right: 5%;
  }

}
.section2 {
  height: 100vh;
  font-family: "Shadows Into Light", cursive;
  font-size: 3.0em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
  padding: 10%;
  border-radius: 0px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  
}


.header_3{
  color: #333333;
  font-family: "Playfair Display", Georgia, serif;
  font-size: 1.7em;
  line-height: 1.9em;
  display: flex;
  margin-top: 0%;
}

.section3 {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column; 
  align-items: center; 
  margin-top: 0%; 
  padding: 10%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 530px) {
  .section3{
    width: 100vw;
    height: 160vh;
    
  }
}

@media screen and (max-width: 520px) {
  .section3{
    width: 100vw;
    height: 180vh;
    
  }
  #carouselPara{
    font-size: 2.0rem !important;
  }
  .header_3{
    font-size: 1.9em !important;
   
  }

}

@media screen and (max-width: 491px) {
  .section3{
    width: 130vw;
    height: 200vh;
    
  }

  @media screen and (max-width: 488px) {
    .section3{
      width: 132vw;
    }
  }

  @media screen and (max-width: 422px) {
    .section3{
      width: 149vw;
    }
  }

  @media screen and (max-width: 416px) {
    .section3{
      width: 148vw;
      height: 230vh;
      
    }

    #carouselPara{
      font-size: 2.5rem !important;
    }
    .header_3{
      font-size: 2.2rem !important;
     
    }

    @media screen and (max-width: 362px) {
      .section3{
        width: 180vw !important;
        height: 260vh;
        
      }
      #carouselPara{
        font-size: 2.5rem !important;
      }
      .header_3{
        font-size: 2.6rem !important;
       
      }
      @media screen and (max-width: 314px) {
        .section3{
          width: 260vw !important;
          height: 290vh;
          
        }

        @media screen and (max-width: 277px) {
          .section3{
            width: 380vw !important;
            height: 350vh;
            
          }
        }

        .bars{
          transform: scale(2.0);
          margin-left: 20% !important;
        }

        #carouselPara{
          font-size: 3.8rem !important;
         
        }
        .header_3{
          font-size: 3.8rem !important;
         
        }

        @media screen and (max-width: 225px) {
          .section3{
            width: 358vw;
            height: 380vh;
            
          }

          .bars{
            margin-left: 4%;
          }
          #carouselPara{
            font-size: 3.6rem !important;
    
          }
          .header_3{
            font-size: 3.6rem !important;
           
           
          }
          @media screen and (max-width: 209px) {
            .section3{
              width: 355vw;
              height: 400vh;
              
            }
            #carouselPara{
              font-size: 4.0rem !important;
            }
            .header_3{
              font-size: 4.0rem !important;
             
            }
            @media screen and (max-width: 177px) {
              .section3{
                width: 370vw;
                height: 400vh;
                
              }
              .bars{
                margin-left: 15%;
                transform: scale(3);
              }
              #carouselPara{
                font-size: 4.3rem !important;
              }
              .header_3{
                font-size: 4.3rem !important;
               
              }
             
            
            }
           
          
          }
         
        
        }
      
      }
    
    }
  
  }

}

@media screen and (max-width: 430px) and (max-height: 932px){
  .section3{
    width: 133vw;
    
  }
}


@media screen and (max-width: 414px) and (max-height: 896px){
  .section3{
    width: 150vw;
    
  }
}

@media screen and (max-width: 412px) and (max-height: 915px){
  .section3{
    width: 150vw;
    
  }
}

@media screen and (max-width: 390px) and (max-height: 844px){
  .section3{
    width: 151vw;
    
  }
}

@media screen and (max-width: 375px) {
  .section3{
    height: 420vh;
    width: 160vw;
    
  }

  

}

@media screen and (max-width: 51px) {
  .section3{
    width: 510vw;
    
  }

  

}

.carousel-container {
  /* 
     Set a specific width and height for the container. 
     This will determine the size of your images. 
     Adjust as needed.
  */
  width: 500px;
  height: 400px;
  position: relative; 
  overflow: hidden; /* This will hide anything that exceeds the width/height of the container. */ 
}

.carousel-image {
  flex-shrink: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will scale the image to fill the container, while maintaining its aspect ratio. */
  opacity: 0; /* Initially all images are invisible. */
  transition: opacity 1s ease-in-out; /* This will animate the fade in/out effect. */
}

.carousel-container{
  transition: transform .5s;
}

.carousel-container:hover{
  transform: scale(1.05);
}


#carouselPara {
  margin-bottom: 10%;
  width: 70%;
  color: #333333;
  font-family: "Playfair Display", Georgia, serif;
  font-size: 1.5em;
  line-height: 1.9em;
  padding: 1em;
  border-radius: 10%;
  transition: transform .5s;
  text-align: center;
}

.carousel-image.current {
  opacity: 1; /* The current image is visible. */
}

.section4 {
  height: 520px;
  color:#006199;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #006199;
  margin-top: 0px;
  padding: 40px;
  border-radius: 0px;
}

@media screen and (max-width: 633px) {
  .section4{
    height: 150vh;
    flex-direction: column;
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 530px) {
  .section4{
    width: 105vw;
  }

}

@media screen and (max-width: 520px) {
  .section4{
    width: 105vw;
    height: 250vh;
  }

  .icon-text{
    font-size: 1.6rem !important;
  }

  .buttonS{
    font-size: 1.6em !important;
  }

}

@media screen and (max-width: 491px) {
  .section4{
    width: 135vw;
    height: 300vh;
  }

  .icon-text{
    font-size: 1.8rem !important;
  }

  .buttonS{
    font-size: 1.8em !important;
  }

}

@media screen and (max-width: 422px) {
  .section4{
    width: 151vw;
  }
}

@media screen and (max-width: 416px) {
  .section4{
    width: 150vw;
    height: 380vh;
  }

  .icon-text{
    font-size: 2.0rem !important;
  }

  .buttonS{
    font-size: 2.0rem !important;
  }

  .icon{
    transform: scale(1.3);
  }

}

@media screen and (max-width: 414px) and (max-height: 896px){
  .section4{
    width: 151vw;
  }

}

@media screen and (max-width: 375px) {
  .section4{
    width: 160vw;
  }
}

@media screen and (max-width: 362px) {
  .section4{
    width: 178vw;
    height: 450vh;
  }

  .icon-text{
    font-size: 2.3rem !important;
  }

  .buttonS{
    font-size: 2.6rem !important;
  }

  .icon{
    transform: scale(1.3);
  }

}


@media screen and (max-width: 314px) {
  .section4{
    width: 258vw;
    height: 800vh;
  }

  .icon-text{
    font-size: 3.5rem !important;
    margin-top: 42% !important;
    width: 15rem !important;
  }

  .buttonS{
    font-size: 3.5rem !important;
    margin-top: 70px !important;
    margin-left: 6% !important;
  }

  .icon{
    transform: scale(2.0);
  }

}


@media screen and (max-width: 209px) {
  .section4{
    width: 273vw;
    height: 800vh;
  }

  .icon-text{
    font-size: 3.7rem !important;
    margin-top: 42% !important;
    width: 15rem !important;
  }

  .buttonS{
    font-size: 3.7rem !important;
    margin-top: 70px !important;
    margin-left: 6% !important;
  }

  .icon{
    transform: scale(2.2);
  }

}

@media screen and (max-width: 277px) {
  .section4{
    width: 371vw;
    height: 850vh;
  }

  .icon-text{
    font-size: 4.2rem !important;
    margin-top: 42% !important;
    width: 15rem !important;
  }

  .buttonS{
    font-size: 4.2rem !important;
    margin-top: 70px !important;
    margin-left: 6% !important;
  }

  .icon{
    transform: scale(2.0);
  }

}

@media screen and (max-width: 51px) {
  .section4{
    width: 385vw;
    height: 850vh;
  }

  

}



.icon-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 0;
}

.icon{
  transition: transform .5s;
  color: #FFFFFF; 
  
}

.icon:hover{
  transform: scale(1.1);
  
}

.icon-text{
  font-family: "Playfair Display", serif;
  font-size: 110%;
  color:#e0e0e0;
  text-align: center;
  font-weight: 300;
  width:10rem;
  margin-top: 1.5rem;
  
}

.buttonS{
  font-size: 1em;
  font-family: "Playfair Display", serif;
  padding: 0.75rem 1.5rem; /* Replaces width and height */
  color: #006199; /* Same color as the section background */
  font-weight: 560; /* Slightly bold font */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  cursor: pointer; /* Changes the cursor on hover */
  text-decoration: none; /* Removes underline if the button is a link */
  margin-top: 25px;

}

.buttonS:hover {
  background-color: #006199; /* Section background color */
  color: #ffffff; /* White text on hover */
}

