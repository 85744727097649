.main-area{
    display: flex;
    justify-content: space-around;
    padding: 40px;
    align-items: center;
    margin-bottom: 500px;
}

.job-info-desc{
    font-family: "Playfair Display", Georgia, serif;
    text-align: center;
    line-height: 1.9em;
    width: 50%;
    font-size: 1.5em;
    color: #000000;
}

@media screen and (max-width: 1024px) and (max-height: 1366px){
  .job-info-desc{
    margin-top: 120px;
  }
}

@media screen and (max-width: 912px) and (max-height: 1368px){
  .job-info-desc{
    margin-top: 150px;
  }
}

@media screen and (max-width: 637px){
  .job-info-desc{
    margin-top: 260px;
  }
}

@media screen and (max-width: 534px){
  .job-info-desc{
    margin-top: 270px;
  }
}

@media screen and (max-width: 430px) and (max-height: 932px){
  .job-info-desc{
    font-size: 1.3em;
  }

}

@media screen and (max-width: 414px) and (max-height: 896px){
  .job-info-desc{
    font-size: 1.5em;
  }

  
}

@media screen and (max-width: 407px){
  .job-info-desc{
    margin-top: 100px;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px){
  .job-info-desc{
    font-size: 1.5em;
  }
}

@media screen and (max-width: 360px) and (max-height: 640px){
  .job-info-desc{
    font-size: 1.0em;
  }
}

@media screen and (max-width: 314px){
  .job-info-desc{
    font-size: 2.6em;
  }
}

@media screen and (max-width: 280px) and (max-height: 653px){
  .job-info-desc{
    font-size: 2.3em;
  }

}

@media screen and (max-width: 174px){
  .job-info-desc{
    font-size: 3em;
  }
}